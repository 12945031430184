.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
h1,h2,h3,h4,h5,h6{font-family: Poppins, Helvetica, sans-serif;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.edu, .keyskill{margin-top: 30px; text-align: left;}
/* about css */
.header{width:160px; padding:0px; background-color: #262b37; position: fixed; height: 100%; border-right: 5px solid #515560;}
.header-image{padding: 30px;}
.header h1{font-size: 24px ;}
.site-nav ul.nav{display: block;}
.header-image img{width: 100%; height: auto; border-radius: 50%;}
header, header a, header a:hover{color:#fff; text-decoration: none;}
header li, header ul{list-style-type: none; text-align: center; margin: 0; padding: 0;}
.site-nav ul li a{padding: 0; line-height:42px; display: block; box-sizing: border-box; border-top:1px solid #262b37; border-bottom:1px solid #262b37;border-right: 3px solid #262b37; }
.site-nav ul li a:hover, .site-nav ul li a.active{border-right: 3px solid #00bdbd; border-top:1px solid #303747; border-bottom:1px solid #303747}
.copyrights{font-size: 12px; color: #ccc; }
.site-title-block{padding-bottom: 30px;}
.social-links{padding: 30px 0;}

.social-links a{ margin: 0 0 0 15px; padding:0px 5px; border-radius:50%;}
.site-main {margin-left: 160px;}
.page-layout {position: relative; min-height: 100%;height: 100%;}
.pt-wrapper {position: relative;width: 100%;overflow: hidden; max-width: 100%;height: 100%; padding: 0;z-index: 1;background-color: transparent;}
.pt-page {background: #00bdbd;padding-left: 15px;margin: 0 auto;}
/* about css end */
.portfolio{height:100%; background: rgba(172, 174, 175, 0.2);
  overflow: hidden;
  border-bottom: 1px solid #ccd;
  padding: 30px 0 0px;}
.contact{height: 100%; padding: 50px 0;background: #f2f2f2; }
.contact form{width: 60%; margin: 0 auto;}
.about-me{color: #fff; text-align: left; padding: 50px 30px 50px 50px; }
.img-circle {
  border-radius: 50%;
}
.subpages {position: relative;width: 100%;max-width: 100%;height: 100%;max-height: 100%;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
h3{font-size: 18px; text-shadow: 2px 1px 5px #fff;}
.skill{color:#262b37; background-color: #fff;padding: 30px 30px 0; border-radius: 20px;   margin-right: 40px;}
.skill i{float: left; font-size: 50px; padding-right: 20px;}
.skillset{display: flex;}
.proPic img{width: 100%;}
.pt-page-current, .no-js .pt-page {
  visibility: visible;
  z-index: 90;
  opacity: 1;
  overflow: hidden;
}
.bntDownload {font-size: 16px;font-family: 'Open Sans', sans-serif;font-weight: 700;background: #474d5d;
  padding: 16px 22px;display: inline-block; color:#fff;
  }
  .bntDownload:hover {
    background: #373d4c;
    color: #CCCCCC;
    text-decoration: none;
}
.skill .row{ padding-bottom: 30px;}
.tab-content{font-size: 14px; padding-right:30px;}
/* Resume css */
.resume{height:100%; overflow: hidden;}
.progress{height:5px !important;}
.resume{padding: 50px;}
.resume h5{font-size: 15px; color:#888}
.section-title{border-bottom:2px solid #eeeeee; padding-bottom: 10px; text-align: left;}
.skill-border{border: 1px solid #0099cc;padding: 2px; border-radius:5px;}
.keyskill{text-align: left; padding:0 0 30px 0;}
.keyskill h4 {font-size: 14px; margin:10px 0 4px;}
.resume h3{font-size: 1.25rem; display: inline-block; border-bottom: 1px solid #0099cc; padding-bottom:5px;}
.keyskill h4 span{float: right; font-size: 12px; color: #acacac;}
.edu-item{display: flex; padding-left: 0px;}
.devider{border-left: 2px solid #eee; max-width: 1% !important; position: relative;}
.devider:after{width:10px;height:10px;border-radius: 5px; background: #fff; border: 2px solid #0099cc; 
  position: absolute; top:24px; display: block; content: ""; left: -6px;}
.edu-item p{font-size:14px ; color:#888;}
.edu-item h4{font-size: 18px;}
.right-panel{padding-top: 20px;}
.left-panel{text-align: right; padding-top:20px}
.edu-item h5{font-size: 16px; color:#212529; margin: 0;}
.left-panel span{font-size: 12px; color:#888;}
.featured-boxes .panel h3{ color: #474d5d;font-family: "greycliff_cfextra_bold","Helvetica Bold",Helvetica,Arial,sans-serif;
  font-size:26px; margin-bottom: 10px;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.featured-boxes .panel{border-radius:0; font-size:18px; color: #00bdbd; text-align:center; padding:0 30px 30px; margin:40px 20px 0 0; -moz-box-shadow: 0 0 20px rgba(0,0,0,0.06);
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.06);
  box-shadow: 0 0 20px rgba(0,0,0,0.06); height:230px; background: #fff;}
.featured-boxes .panel i{	border:4px solid #00bdbd;width: 80px;
  height: 80px;
  line-height: 75px;border-radius: 50%; font-size: 40px;margin-top: -37px;background: #fff; -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.featured-boxes .panel p {
  color: #555;
  font-size: 16px;
}
.featured-boxes .panel p{color:#555}
.featured-boxes .panel:hover{cursor:pointer}
.featured-boxes .panel:hover i{background-color: #00bdbd;
  color: #fff;
  -moz-transform: rotateZ(-360deg);
  -ms-transform: rotateZ(-360deg);
  -webkit-transform: rotateZ(-360deg);
  transform: rotateZ(-360deg);
}

/* Resume css end */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and  (max-device-width:767px){ 
  .header {
    width:100%;
    margin-top: -60px;
    position: fixed;
    height:60px;
    border-right: 0;
    z-index: 99;
}
.header-image {padding: 10px;width: 60px; float: left;}
.copyrights{position: fixed; bottom: 0; width: 100%; line-height: 40px; background: #262b37;}
.mob-nav{float:right; margin:12px 10px; padding:5px 10px; border: 1px solid #ccc4; border-radius:5px ; color:#00bdbd}
.site-nav{display: none;}
.header h1 {display:none;}
.social-links {padding: 0;text-align:right; margin-top: -10px;}
.social-links a {margin: -5px 0 0 15px;padding: 0;border-radius: 50%;border: 1px solid #ccc4;width: 30px;height: 30px;
  display: inline-block;text-align: center;line-height: 30px;color: #00bdbd;}
  .site-main {margin-left:0px; margin-top: 60px;}
  .about-me,.resume,.portfolio,.contact{padding:15px;}
  .featured-boxes .panel{margin: 40px 0px 0 0;}
  .site-nav{background: #262b37;position: absolute;width: 75%;top: 60px;text-align: left;right: 0; border-left: 5px solid #515560; }
  .site-nav ul li a:hover, .site-nav ul li a.active {border-left: 3px solid #00bdbd;border-right:0 }
}


.img-responsive {
  max-width: 100%;
  height: auto;
}




.portfolio-grid > .view {
  width: 30%;
  float: left;
  margin-left: 5%;
  text-align: center;
  position: relative;
  cursor: default;
  z-index: 1;
  border: 6px solid #7e8fa3;
  border-top-width: 25px;
  border-radius: 5px;
}

.portfolio-grid > .view:first-child {
  margin-left: 0;
}

.view > .view-wrapper {
  height: 220px;
  overflow: hidden;
}

.view .mask,
.view .content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.view img {
  display: block;
  position: relative;
}

.brand-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.view h2 {
  color: #ffa500;
  text-align: center;
  position: relative;
  font-size: 18px;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding: 10px;
  margin: 20px 0 0 0;
}

.view p {
  font-family: "Times New Roman", serif;
  font-style: italic;
  font-size: 16px;
  position: relative;
  color: #fff;
  padding: 0px 10px 0px;
  text-align: center;
  
}

.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 20px;
  background: #000;
  color: #fff;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;

  box-shadow:1px 1px 1px #000;
  border-radius: 25px;

}

.view a.info:hover {
  color: #fff;
  text-decoration: none;
  box-shadow: 0 0 5px #000;
}

.view-portfolio img {
  transition: all 0.2s linear;
}

.view-portfolio .mask {
  opacity: 0;
  background-color: rgba(10, 50, 73, 0.842);
  transition: all 0.4s ease-in-out;
}

.view-portfolio h2 {
  transform: translateY(-100px);
  opacity: 0;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  transition: all 0.2s ease-in-out;
}

.view-portfolio p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}

.view-portfolio a.info {
  opacity: 0;
  transition: all 0.2s ease-in-out;

}

.view-portfolio:hover img {
  transform: scale(1.1);
}

.view-portfolio:hover .mask {
  opacity: 1;
}

.view-portfolio:hover h2,
.view-portfolio:hover p,
.view-portfolio:hover a.info {
  opacity: 1;
  transform: translateY(0px);
}

.view-portfolio:hover p {
  transition-delay: 0.1s;
}

.view-portfolio:hover a.info {
  transition-delay: 0.2s;
}

.portfolio-link {
  color: #545454;
  padding: 8px 16px;
  margin-top: 25px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
}

.portfolio-link:hover {
  background-color: #eee;
}

.browser-interface {
  position: absolute;
  top: -18px;
  width: 100%;
  background-color: #7e8fa3;
  z-index: 500;
}

.history-arrows-section {
  float: left;
  height: 8px;
  text-align: left;
  margin-right: 2.62318%;
  background-color: #7e8fa3;
}

.back-section-icon,
.next-section-icon {
  margin-top: 1px;
  vertical-align: top;
}

.back-section-icon {
  width: 10px;
  height: 10px;
  fill: #fff;
}

.next-section-icon {
  width: 10px;
  height: 10px;
  fill: #fff;
}

.url-section,
.search-section {
  float: right;
  height: 12px;
  background-color: #fff;
}

.url-section {
  width: 55.07246%;
  text-align: right;
  margin-right: 2.898555%;
  position: relative;
}

.search-section {
  width: 27.17391%;
  text-align: left;
}

.search-section-icon,
.url-section-icon {
  width: 10px;
  height: 10px;
  margin-top: 1px;
  vertical-align: top;
  fill: #7e8fa3;
}

.search-section-icon {
  margin-left: 5px;
  fill: #7e8fa3;
}

.url-section-icon {
  margin-right: 5px;
  fill: #7e8fa3;
}

.url-section-text {
  color: #7e8fa3;
  font-size: 12px;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  line-height: 12px;
  position: absolute;
  left: 4px;
}

@media screen and (max-width: 820px) {
  .portfolio-grid > .view {
    width: 45%;
    float: none;
    margin: auto;
    margin-bottom: 20px;
  }

  .portfolio-grid > .view:first-child {
    margin-left: auto;
  }

  .portfolio-link {
    color: #545454;
    padding: 8px 16px;
    margin-top: 12px;
    background-color: #eee;
    transition: 0.5s;
  }

  .portfolio-link:hover {
    color: #ef7b00;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-grid > .view {
    width: 55%;
  }
}

@media screen and (max-width: 560px) {
  .portfolio-grid > .view {
    width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .portfolio-grid > .view {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .portfolio-grid > .view {
    width: 100%;
  }
}

/* -------------------------------- 

Site Footer

-------------------------------- */

.main-footer {
  background-color: #121212;
}

.copyright {
  width: 60%;
  float: left;
  color: #cecece;
  line-height: 80px;
}


/* -------------------------------- 

Portfolio Page

-------------------------------- */
h2.post-title-portfolio{font-size: 24px ;}
.portfolio-content a {
  color: #262b37;
  text-decoration: none;
}

.portfolio-content h2 a {
  font-size: 24px;
}
.portfolio-content a:hover {
  color:#0099cc;
  text-decoration: none;
}

.title-page {
  color: #545454;
  font-size: 24px;
  font-weight: 400;
  margin: 20px 0px 26px 0px;
}

.home-portfolio-text > .post-title-portfolio {
  margin-bottom: 0px;
}

.portfolio-section {
  margin-top: 25px;
  margin-bottom: 50px;
  text-align: center;
  overflow: hidden;
}

.portfolio-content,
.portfolio-area {
  width: 98%;
}

.portfolio-categ {
  margin-bottom: 15px;
}

.portfolio-categ li {
  display: inline;
  margin-right: 10px;
}

.portfolio-area li {
  width: 30%;
  float: left;
  margin-left: 5%;
  margin-bottom: 18px;
  overflow: hidden;
}

.portfolio-area > .portfolio-area__new-row {
  margin-left: 0;
}

.home-portfolio-text {
  margin-top: 10px;
}

li.active a {
  text-decoration: underline;
}

.main-portfolio-grid > .view {
  text-align: center;
  position: relative;
  cursor: default;
  z-index: 1;
  border: 6px solid #7e8fa3;
  border-top-width: 25px;
  border-radius: 5px;
}

.portfolio-example {
  margin-top: 50px;
  margin-bottom: 50px;
}

.portfolio-example-block {
  padding: 2% 2.5%;
  background-color: #fff;
  border: 1px solid #ddd;
}

.portfolio-example-block h1 {
  font-size: 24px;
  font-weight: 400;
}

.portfolio-example-block ul {
  list-style-type: none;
  margin-left: 15px;
}

.back {
  text-align: center;
}

@media screen and (max-width: 912px) {
  .post-title-portfolio {
    font-size: 18px;
  }
}

@media screen and (max-width: 844px) {
  .portfolio-area li {
    width: 42%;
    float: none;
    margin: auto;
    margin-bottom: 20px;
  }

  .portfolio-area > .portfolio-area__new-row {
    margin: auto;
    margin-bottom: 20px;
  }
  .portfolio-content, .portfolio-area {
    width: 100%;margin: 0;
    padding: 0;
}
.contact form {
  width: 100%;
  margin: 0 auto 50px;
}
}

@media screen and (max-width: 620px) {
  .portfolio-area li {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .portfolio-area li {
    width: 80%;
  }
}

@media screen and (max-width: 360px) {
  .portfolio-area li {
    width: 100%;
  }
}
